import React from 'react';
import styles from './Section6.module.css';
import foodImage from '../../Images/cake5.jpg'; // Use the correct path to your image
import { useNavigate } from 'react-router-dom';

const Section6 = () => {

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/about');
    };

    return (
        <div className={styles.section}>
            <div className={styles.content}>
                <h2 className={styles.heading}>OUR STORY</h2>
                <p className={styles.text}>
                    Cake Company is owned by the professionals who undertook their chef training in one of the most reputed Hotel Industry in 2012 in New Delhi i.e. THE ASHOK & TAJ Group restaurants & continued working in Hospitality group for 7 years
                </p>
                <p className={styles.text}>
                    After receiving various accolades in their field, we decided to start our own venture with the name of Cake Company where our prime agenda to serve our customers a quality product with great taste & great customer service. So, in 2019, we founded CAKE COMPANY and set about creating high quality eggless cakes & we serve both type of customers (Corporate & Retail).</p>

                <button className={styles.button} onClick={handleButtonClick}>More About Us</button>
            </div>
            <div className={styles.imageContainer}>
                <img src={foodImage} alt="Delicious food" className={styles.image} />
            </div>
        </div>
    );
};

export default Section6;
