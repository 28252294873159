
import './App.css';
import AppRoutes from './components/Constant/AppRoutes';

function App() {
  return (
    <div className="App">
      <AppRoutes />
    </div>

  );
}

export default App;
