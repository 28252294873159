import React, { useState, useEffect } from 'react';
import styles from './Header.module.css';
import logo from '../../Images/logo.png';
import Login from '../../Login/Login';
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import { Link } from 'react-router-dom';
import axiosInstance from '../../../api'; // Import the axios instance

const Header = () => {
    const [showLogin, setShowLogin] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isOnline, setIsOnline] = useState(false); // State to track restaurant status

    useEffect(() => {
        const token = localStorage.getItem('tokenId');
        if (token) {
            setIsAuthenticated(true);
        }
        // Check the initial status of the restaurant (optional)
        fetchRestaurantStatus();
    }, []);

    const handleShowLogin = () => {
        setShowLogin(true);
    };

    const handleCloseLogin = () => {
        setShowLogin(false);
    };

    const handleLogin = () => {
        setIsAuthenticated(true);
        setShowLogin(false);
    };

    const handleLogout = () => {
        setIsAuthenticated(false);
        localStorage.removeItem('tokenId'); // Remove the token on logout
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleToggleChange = async () => {
        try {
            const newStatus = !isOnline;
            await axiosInstance.post(process.env.REACT_APP_MASTER_CLIENT, null, {
                params: {
                    client: process.env.REACT_APP_CLIENT,
                    isAvailable: newStatus,
                },
            });
            setIsOnline(newStatus);
        } catch (error) {
            console.error('Error updating restaurant status', error);
        }
    };

    // Fetch the restaurant's initial status (optional)
    const fetchRestaurantStatus = async () => {
        try {
            const response = await axiosInstance.get(process.env.REACT_APP_MASTER_CLIENT, {
                params: { client: process.env.REACT_APP_CLIENT },
            });
            setIsOnline(response.data.responseObject);
        } catch (error) {
            console.error('Error fetching restaurant status', error);
        }
    };

    const isAdmin = localStorage.getItem('role') === '1';

    return (
        <header className={styles.header}>
            <div className={styles.logo}>
                <Link to="/">
                    <img src={logo} alt="Logo" />
                </Link>
            </div>
            <nav className={`${styles.navDesktop} ${menuOpen ? styles.open : ''}`}>
                <ul>
                    <li><a href="/">HOME</a></li>
                    <li><a href="/about">ABOUT US</a></li>
                    <li><a href="/order-now">MENU</a></li>
                    <li><a href="/contact">CONTACT</a></li>
                    <li><a href="/order-now">ORDER NOW</a></li>
                    {isAuthenticated && <li><a href="/orders">MY ORDERS</a></li>}
                    {isAdmin && isAuthenticated && <li><a href="/delivery-boy">DELIVERY BOY</a></li>}
                    {isAdmin && isAuthenticated && <li><a href="/coupon">COUPONS</a></li>}
                    {!isAuthenticated && <li><a href="#" onClick={handleShowLogin}>SIGN IN</a></li>}
                    <ProfileMenu isAuthenticated={isAuthenticated} handleLogout={handleLogout} />
                    {isAdmin && (
                        <li className={styles.toggleContainer}>
                            <label className={styles.switch}>
                                <input
                                    type="checkbox"
                                    checked={isOnline}
                                    onChange={handleToggleChange}
                                />
                                <span className={styles.slider}></span>
                            </label>
                            <span className={`${styles.statusText} ${isOnline ? styles.statusOnline : styles.statusOffline}`}>
                                {isOnline ? 'Online' : 'Offline'}
                            </span>
                        </li>
                    )}
                </ul>
            </nav>
            <div className={styles.navMobile}>
                <button className={styles.menuIcon} onClick={toggleMenu}>
                    <div></div>
                    <div></div>
                    <div></div>
                </button>
                {menuOpen && (
                    <div className={styles.mobileMenu}>
                        <ul>
                            <li><a href="/">HOME</a></li>
                            <li><a href="/about">ABOUT US</a></li>
                            <li><a href="/order-now">MENU</a></li>
                            <li><a href="/contact">CONTACT</a></li>
                            <li><a href="/order-now">ORDER NOW</a></li>
                            {isAuthenticated && <li><a href="/orders">MY ORDERS</a></li>}
                            {isAdmin && isAuthenticated && <li><a href="/delivery-boy">DELIVERY BOY</a></li>}
                            {isAdmin && isAuthenticated && <li><a href="/coupon">COUPONS</a></li>}
                            {!isAuthenticated && <li><a href="#" onClick={handleShowLogin}>SIGN IN</a></li>}
                            <ProfileMenu isAuthenticated={isAuthenticated} handleLogout={handleLogout} />
                            {isAdmin && (
                                <li className={styles.toggleContainer}>
                                    <label className={styles.switch}>
                                        <input
                                            type="checkbox"
                                            checked={isOnline}
                                            onChange={handleToggleChange}
                                        />
                                        <span className={styles.slider}></span>
                                    </label>
                                    <span className={`${styles.statusText} ${isOnline ? styles.statusOnline : styles.statusOffline}`}>
                                        {isOnline ? 'Online' : 'Offline'}
                                    </span>
                                </li>
                            )}
                        </ul>
                    </div>
                )}
            </div>
            <Login show={showLogin} handleClose={handleCloseLogin} handleLogin={handleLogin} />
        </header>
    );
};

export default Header;
