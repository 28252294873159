import React from "react";
import Header from "../Constant/Header/Header";
import Footer from "../Constant/Footer/Footer";
import ContactSection2 from "./ContactSection2.js";
import ContactSection1 from "./ContactSection1.js";
import ContactSection3 from "./ContactSection3.js";

const Contact = () => {

    return (
        <div>
            <div>
                <Header />
            </div>
            <div>
                <ContactSection1 />
                <ContactSection2 />
                <ContactSection3 />

            </div>
            <div>
                <Footer />
            </div>

        </div>
    );
};

export default Contact;