import React from "react";
import Header from "../Constant/Header/Header";
import Footer from "../Constant/Footer/Footer";
import AboutSection3 from "./AboutSection3";
import AboutSection4 from "./AboutSection4";
import AboutSection1 from "./AboutSection1";
import AboutSection2 from "./AboutSection2.js";

const About = () => {

    return (
        <div>
            <div>
                <Header />
            </div>
            <div>
                <AboutSection1 />
                <AboutSection2 />
                <AboutSection3 />
                <AboutSection4 />
            </div>
            <div>
                <Footer />
            </div>

        </div>
    );
};

export default About;